import type { PaymentTermFrequency } from "@planetadeleste/vue-mc-gw";
import dayjs from "dayjs";

export interface TimeFrequencyCalc {
  frequency: PaymentTermFrequency;
  frequency_interval: number;
  delay?: number;
}

export interface TimePeriodFunction {
  helper?: TimePeriodHelper;
  next?: string;
  obNext?: dayjs.Dayjs;
  config?: TimeFrequencyCalc;
}

class TimePeriodHelper {
  private _date: dayjs.Dayjs;
  private readonly _currentDate: dayjs.Dayjs;
  private readonly _period: TimeFrequencyCalc;

  constructor(sDate: string | Date, obPeriod: TimeFrequencyCalc) {
    this._period = obPeriod;
    this._date = dayjs(sDate);
    const now = dayjs();
    const d = new Date(now.year(), now.month(), this._date.day());
    this._currentDate = dayjs(d);
  }

  get config() {
    return this._period;
  }

  get obNextDate() {
    if (!this.config.frequency || !this.config.frequency_interval) {
      return this._currentDate;
    }

    return this._currentDate.add(
      this.config.frequency_interval,
      this.config.frequency
    );
  }

  get sNextDate() {
    return this.obNextDate.format("YYYY-MM-DD");
  }
}

export default function useTimePeriod(
  sDate: string | Date,
  obPeriod: TimeFrequencyCalc
): TimePeriodFunction {
  if (!sDate || !obPeriod.frequency || !obPeriod.frequency_interval) {
    return {
      next: undefined,
      obNext: undefined,
      config: undefined,
      helper: undefined,
    };
  }

  const helper = new TimePeriodHelper(sDate, obPeriod);

  return {
    next: helper.sNextDate,
    obNext: helper.obNextDate,
    config: helper.config,
    helper,
  };
}
