<template>
  <form-field-text
    :disabled="disabled"
    :hint="sHint"
    :label="sLabel"
    :persistent-hint="!!sHint"
    :rules="obRules"
    :single-line="singleLine"
    :value="fValue"
    :wait="500"
    input-type="number"
    required
    v-bind="$attrs"
    @save="onApplyPrice"
    @input:debounce="onSetPrice"
  >
    <template v-if="showBtnReset || appendLabel" #append>
      <v-btn
        v-if="showBtnReset"
        color="secondary"
        icon
        small
        text
        @click="onResetPrice"
      >
        <icon-refresh-cw />
      </v-btn>
      <v-chip
        v-else
        label
        outlined
        x-small
        class="p-0 px-2 mt-1 text-uppercase"
      >
        {{ $t(sChipLabel) }}
      </v-chip>
    </template>
  </form-field-text>
</template>

<script lang="ts">
import { Component, Mixins, Prop } from "vue-property-decorator";
import TaxTypeCacheMixin from "@/modules/taxtypes/mixins/TaxTypeCacheMixin";
import type { RoundByPosition } from "@/plugins/helpers";
import { countDecimals, percent, round, roundBy } from "@/plugins/helpers";
import type { CurrencyRate } from "@planetadeleste/vue-mc-gw";
import { ConfigModule } from "@/store/config";
import { EventBus } from "@/services/event-bus";
import { defaults, delay, isNaN, isNil, isUndefined } from "lodash";
import { debug } from "@/composables/debug";

@Component
export default class UnitPriceField extends Mixins(TaxTypeCacheMixin) {
  @Prop([String, Number]) readonly value!: number;
  @Prop([String, Number]) readonly currencyId!: number;
  @Prop(String) readonly hint!: string;
  @Prop(Boolean) readonly showBtnReset!: boolean;
  @Prop(Boolean) readonly priceWithTax!: boolean;
  @Prop(Boolean) readonly disabled!: boolean;
  @Prop(Boolean) readonly hintLabel!: boolean;
  @Prop(Boolean) readonly appendLabel!: boolean;
  @Prop({ type: Object, default: () => {} }) readonly rules!: Record<
    string,
    any
  >;
  @Prop({ type: Boolean, default: () => true }) readonly singleLine!: boolean;

  fInitialValue = 0;

  /**
   * @var {number} Decimal places to round unit price for display only
   */
  iDecimals = 2;

  get taxPrice() {
    if (!this.value || !this.taxPercent) {
      return 0;
    }

    return percent(this.value, this.taxPercent, true);
  }

  get fValue() {
    if (this.value == 0) return 0;
    if (!this.value && !this.fInitialValue) {
      return 0;
    }

    const fValue = this.fInitialValue || this.value;

    if (!this.fInitialValue) {
      this.fInitialValue = fValue;
    }

    // fValue = this.roundValue(fValue);

    return round(fValue, this.iDecimals);
  }

  get currencyRate(): CurrencyRate | undefined {
    if (!this.currencyId || !ConfigModule.companyCurrencyRates.length) {
      return undefined;
    }

    return ConfigModule.companyCurrencyRates.find({
      currency_id: this.currencyId,
    });
  }

  get sLabel(): string {
    if (isUndefined(this.priceWithTax)) {
      return "unit.price";
    }

    const sLabel = this.priceWithTax ? "with" : "without";
    return `unit.price.${sLabel}.tax`;
  }

  get sChipLabel(): string {
    const sLabel = this.priceWithTax ? "with" : "without";
    return `label.${sLabel}.tax`;
  }

  get sHint(): string | undefined {
    return this.hintLabel ? (this.$t(this.sLabel) as string) : this.hint;
  }

  get obRules() {
    return defaults(this.rules, {
      required: !this.disabled,
      double: true,
    });
  }

  onResetPrice() {
    this.$emit("price:reset", true);
  }

  onSetPrice(fValue: number, bCountDecimals = true) {
    if (isNil(fValue) || isNaN(fValue)) {
      fValue = 0;
    }

    if (this.disabled || this.fInitialValue === fValue) {
      return;
    }

    if (bCountDecimals) {
      this.iDecimals = countDecimals(fValue);
    }

    /*
    if (this.priceWithTax && this.obTaxType) {
      fValue = math.subtract(
        fValue,
        reverseTax(fValue, this.obTaxType.percent)
      );
    }
*/

    debug("Initial price: %s", fValue);
    this.fInitialValue = fValue;
    this.$emit("price:update", fValue);
  }

  onSetPriceDelayed() {
    delay(() => {
      this.onSetPrice(this.value, false);
    }, 1000);
  }

  onApplyPrice() {
    if (this.disabled) {
      return;
    }

    this.$emit("price:apply", true);
  }

  roundValue(fValue: number) {
    /*
    if (!this.priceWithTax && this.taxPrice) {
      fValue = math.add(fValue, this.taxPrice);
    }
*/

    let fRound: RoundByPosition = 0.01;

    if (this.currencyRate && this.currencyRate.round) {
      fRound = this.currencyRate.round as RoundByPosition;
    }

    return roundBy(fValue, fRound);
  }

  created() {
    EventBus.on("invoice.currency.rate.changed", this.onSetPriceDelayed);
    EventBus.on("invoice.product.changed", this.onSetPriceDelayed);
  }

  beforeDestroy() {
    EventBus.off("invoice.currency.rate.changed");
    EventBus.off("invoice.product.changed");
  }
}
</script>
